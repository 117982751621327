export const isNew = (date: Date, periodDays: number): boolean => {
    const publishedDate = date;
    const currentDate = new Date();
    const differenceInMilliseconds = currentDate.getTime() - publishedDate.getTime();
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
    return differenceInDays <= periodDays;
};




const units: string[] = [
    "Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine",
    "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen",
    "Seventeen", "Eighteen", "Nineteen"
];

const tens: string[] = [
    "", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"
];

export const ConverterIntoWords = (amount: number): string => {
    if (amount < 0) {
        throw new Error("Amount must be a non-negative number.");
    }

    if (amount < 20) {
        return units[amount];
    }

    if (amount < 100) {
        return `${tens[Math.floor(amount / 10)]}${amount % 10 > 0 ? ` ${ConverterIntoWords(amount % 10)}` : ""}`;
    }

    if (amount < 1000) {
        return `${units[Math.floor(amount / 100)]} Hundred${amount % 100 > 0 ? ` And ${ConverterIntoWords(amount % 100)}` : ""}`;
    }

    if (amount < 100000) {
        return `${ConverterIntoWords(Math.floor(amount / 1000))} Thousand${amount % 1000 > 0 ? ` ${ConverterIntoWords(amount % 1000)}` : ""}`;
    }

    if (amount < 10000000) {
        return `${ConverterIntoWords(Math.floor(amount / 100000))} Lakh${amount % 100000 > 0 ? ` ${ConverterIntoWords(amount % 100000)}` : ""}`;
    }

    if (amount < 1000000000) {
        return `${ConverterIntoWords(Math.floor(amount / 10000000))} Crore${amount % 10000000 > 0 ? ` ${ConverterIntoWords(amount % 10000000)}` : ""}`;
    }

    return `${ConverterIntoWords(Math.floor(amount / 1000000000))} Arab${amount % 1000000000 > 0 ? ` ${ConverterIntoWords(amount % 1000000000)}` : ""}`;
}